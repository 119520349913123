// React
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
// Auth
import { Auth0Provider } from "@auth0/auth0-react";
// Store
import { StateProvider } from "./store/StateProvider";
import reducer, { initialState } from './store/reducer';
// App
import App from "./App";
// CSS
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider reducer={reducer} initialState={initialState} >
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN}
        clientId={process.env.REACT_APP_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUDIENCE}
      >
        <Router>
          <App />
        </Router>
      </Auth0Provider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
