import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// Store
import { useStateValue } from './store/StateProvider';
import { SET_TOKEN, SET_INITIAL_DATA} from './store/actions'
// Utils
import { fetchGET } from './util'
// Components
import Loading from './components/Loading'
import Layout from './components/Layout/Layout'
// Routes
import Routes from './Routes'

function App() {
  // Auth
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } = useAuth0();
  // Store
  const [{ token }, dispatch] = useStateValue();
  // State
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false)

  // Fetch Accounts (TODO: add get account in api gateway and remove this fetch)
  useEffect(() => {
    if (!token || !user) return
    const fetchData = async () => {
      setIsFetching(true)
      try {
        const res = await Promise.all([
          // fetchGET(`users/${user.email}/accounts`, token),
          fetchGET(`users/${user.email}/bastions`, token),
          fetchGET(`users/${user.email}/sshkeys`, token),
          fetchGET(`users/${user.email}/favorites`, token),
        ])
        dispatch({
          type: SET_INITIAL_DATA,
          payload: res
        })
      } catch (err) {
        setError(err)
      }
      setIsFetching(false)
    }
    fetchData()
  }, [token, user, dispatch])

  // GET Auth0 Token function
  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      dispatch({
        type: SET_TOKEN,
        payload: token
      })
    } catch (error) {
      console.error(error);
    }
  };

  // IF LOADING of FETCHING: return loading
  if (isLoading || isFetching) {
    return <Loading />
  }

  // IF NOT AUTH: login and return loading
  if (!isAuthenticated) {
    loginWithRedirect();
    return <Loading />
  }

  // IF NO TOKEN: Call getToken and return loading
  if (!token) {
    getToken();
    return <Loading />
  }

  // If ERROR
  if (error) return <h1>Error: {error}</h1>

  return (
    <Layout>
      <div className="flex flex-grow">
        <div className="container max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-5">
          <Routes />
        </div>
      </div>
    </Layout >
  );
}

export default App;
