const accounts = [
  {
    "accountId": "951595863153",
    "accountName": "HigherEducation - Marketing",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "655605290743",
    "accountName": "HigherEducation - Product",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "501195815244",
    "accountName": "rv-edusites-nonprod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "664417149482",
    "accountName": "rv-edusites-prod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "930463036949",
    "accountName": "rv-hedata-nonprod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "250519976740",
    "accountName": "rv-hedata-prod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "809724250564",
    "accountName": "rv-heengineering-nonprod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "573847400527",
    "accountName": "rv-heengineering-prod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "418574860591",
    "accountName": "rv-hemanagement-nonprod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "733673129990",
    "accountName": "rv-hemanagement-prod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "767845242477",
    "accountName": "rv-hemarketing-nonprod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "987761851499",
    "accountName": "rv-hemarketing-prod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "692028855056",
    "accountName": "rv-heweb-nonprod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "490394017611",
    "accountName": "rv-heweb-prod",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  },
  {
    "accountId": "725566787105",
    "accountName": "rv-highereducation-sandbox",
    "departmentName": "Education Allocation",
    "ownerName": "Colin Mutter"
  }
]

export default accounts