// React
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// Icons
import { PlusIcon } from '@heroicons/react/solid'
// Auth
import { useAuth0 } from '@auth0/auth0-react'
// Store
import { useStateValue } from '../store/StateProvider'
import { SET_DASHBOARD_DATA } from '../store/actions'
// Utils
import { getDefaultSSHKey, fetchGET } from '../util'
// Components
import Header from '../components/Header'
import BastionList from '../components/BastionList'
import SSHKey from '../components/SSHKey'
import Loading from '../components/Loading'
import Error from '../components/Error'
import AccountListFavorites from '../components/AccountListFavorites'


const Dashboard = () => {
  const { user } = useAuth0();
  const history = useHistory();
  const [{ bastions, sshKeys, favorites, token }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  // Fetch Dashboard Data
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true)
      try {
        const res = await Promise.all([
          fetchGET(`users/${user.email}/bastions`, token),
          fetchGET(`users/${user.email}/sshkeys`, token),
          fetchGET(`users/${user.email}/favorites`, token),
        ])
        dispatch({
          type: SET_DASHBOARD_DATA,
          payload: res
        })
      } catch (err) {
        setError(err)
      }
      setLoading(false)
    }
    fetchDashboardData()
  }, [token, user.email, dispatch])

  if (error) return <Error error={error} />
  if (loading) return <Loading />
  return (
    <div>
      <Header title='Dashboard'>
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => history.push('/accounts')}
        >
          <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Create Bastion
        </button>
      </Header>
      <h3 className="text-lg font-semibold text-gray-400 mt-3">Running Bastions</h3>
      <div className='mt-2 mb-5'>
        <BastionList bastions={bastions} />
      </div>
      <h3 className="text-lg font-semibold text-gray-400">Default SSH key</h3>
      <div className='mt-2 mb-5'>
        {sshKeys && sshKeys.length > 0 ?
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <SSHKey sshKey={getDefaultSSHKey(sshKeys)} actionButtons={false} />
          </div> :
          <h3 className="text-sm font-bold text-indigo-600">You don't have a default SSH Key</h3>
        }
      </div>

      <h3 className="text-lg font-semibold text-gray-400">Account Favorites</h3>
      <div className='mt-2 mb-5'>
        {favorites && favorites.length > 0 ?
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
             <AccountListFavorites accounts={favorites} />
          </div> :
          <h3 className="text-sm font-bold text-indigo-600">You don't have a favorites</h3>
        }
      </div>
    </div>
  )
}

export default Dashboard
