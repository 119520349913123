import { UserIcon, CubeIcon, HeartIcon as HeartIconSolid } from '@heroicons/react/solid'

import { Link } from 'react-router-dom';

export default function AccountList({ accounts, favorites}) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {accounts.map((acc) => (
          <li key={acc.accountId}>
            <Link to={`accounts/${acc.accountId}`} className=" block hover:bg-gray-50">
              <div className="flex">
                <div className="flex-1 px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-indigo-600">{acc.accountName}</p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {acc.departmentName}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-between items-center">
                    <p className="flex items-center text-sm text-gray-500">
                      <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {acc.accountId}
                    </p>
                    <div className="flex items-center text-sm text-gray-500">
                      <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <p>
                        {acc.ownerName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  {favorites.find(a => a.accountId === acc.accountId) === undefined 
                  ? null 
                  : <HeartIconSolid className="flex-shrink-0 mr-1.5 h-6 w-6 text-red-600" aria-hidden="true" />}
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}