// Utils
import { getNameFromTags } from "../../util";

export default function ResourceList({ resources, resourceType, checkedResources, handleCheck }) {
  if (resources && resources.length > 0) {
    return (
      <div className='mb-5'>
        <h3 className="text-sm font-semibold text-gray-400">{resourceType}</h3>
        <div className="bg-white overflow-hidden rounded-md border border-gray-200">
          <ul className="divide-y divide-gray-200">
            {resources.map((r) => {
              return (
                <li key={r.id} onClick={() => handleCheck(r)}>
                  <span className="block hover:bg-gray-50 cursor-pointer">

                    <div className="p-2 flex items-center">
                      <input
                        id={r.id}
                        aria-describedby={`${resourceType}-resources`}
                        type="checkbox"
                        className="focus:ring-indigo-400 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                        onChange={(e) => handleCheck(r)}
                        checked={checkedResources.find(cr => cr.id === r.id) !== undefined ? true : false}
                      />
                      <div className="ml-3 flex flex-col flex-grow">
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="text-sm font-medium text-gray-900">{r.id}</p>
                            {r.clusterId ? <p className="text-gray-500 text-sm">{r.isWriter ? ' Writer' : ' Reader'}</p> : ''}
                          </div>
                          <div className="ml-2 flex-shrink-0 flex">
                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {r.vpcId}
                            </p>
                          </div>
                        </div>
                        <p className="text-gray-500 block text-sm">{getNameFromTags(r.tags)}</p>
                      </div>
                    </div>


                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
  return <></>
}
