import {
  SET_TOKEN,
  SET_INITIAL_DATA,
  SET_DASHBOARD_DATA,
  SET_ACCOUNTS,
  SET_ACCOUNT,
  SET_RESOURCES,
  SET_BASTIONS,
  SET_BASTION,
  SET_SSHKEYS,
  SET_FAVORITES,
} from './actions'
import accountsData from './accountsData'

export const initialState = {
  token: false,
  accounts: accountsData,
  account: {},
  resources: {},
  vpcs: [],
  bastions: [],
  bastion: {},
  sshKeys: [],
  favorites: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    case SET_INITIAL_DATA:
      return {
        ...state,
        bastions: action.payload[0],
        sshKeys: action.payload[1],
        favorites: action.payload[2]
      }
    // case SET_INITIAL_DATA:
    //   return {
    //     ...state,
    //     accounts: action.payload[0],
    //     bastions: action.payload[1],
    //     sshKeys: action.payload[2],
    //     favorites: action.payload[3]
    //   }
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        bastions: action.payload[0],
        sshKeys: action.payload[1],
        favorites: action.payload[2]
      }
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case SET_RESOURCES:
      return {
        ...state,
        resources: action.payload.resources,
        vpcs: action.payload.vpcs
      }
    case SET_BASTIONS:
      return {
        ...state,
        bastions: action.payload
      }
    case SET_BASTION:
      return {
        ...state,
        bastion: action.payload
      }
    case SET_SSHKEYS:
      action.payload.sort((a, b) => b.isDefault - a.isDefault);
      return {
        ...state,
        sshKeys: action.payload
      }
    case SET_FAVORITES:
      return {
        ...state,
        favorites: action.payload
      }
    default:
      return state;
  }
}

export default reducer;

