// React
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
// Auth
import { useAuth0 } from '@auth0/auth0-react';
// Icons
import { XCircleIcon, HeartIcon as HeartSolid } from '@heroicons/react/solid'
import { HeartIcon as HeartOutline } from '@heroicons/react/outline'

// Store
import { useStateValue } from '../store/StateProvider';
// Utils
import { fetchGET, fetchPOST, fetchDELETE, filterResourcesByVpcId, getNameFromTags, wait } from '../util'
// Components
import VpcsRadio from '../components/Form/VpcsRadio'
import ResourcesCheckbox from '../components/Form/ResourcesCheckbox'
import Loading from '../components/Loading'
import Modal from '../components/Modal'
import CreateBastion from '../components/Form/CreateBastion'
import Alert from '../components/Alert'
import Header from '../components/Header'
import Error from '../components/Error'

const Account = () => {
  const { id } = useParams()
  const history = useHistory()
  const { user } = useAuth0()
  // Store
  const [{ accounts, favorites, token }, dispatch] = useStateValue();

  // State
  const [account, setAccount] = useState(null)
  const [resources, setResources] = useState([])
  const [vpcs, setVpcs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectedVpc, setSelectedVpc] = useState(null)
  const [checkedResources, setCheckedResources] = useState([])
  const [filteredResources, setFilteredResources] = useState(resources)
  const [showClearButton, setShowClearButton] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertVpc, setAlertVpc] = useState(null)
  const [openCreateBastion, setOpenCreateBastion] = useState(false)
  const [favoritesEnabled, setFavoritesEnabled] = useState(false)

  // Set Account
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const acc = accounts.find(a => a.accountId === id)
        if (acc) {
          setAccount(acc)
          const data = await fetchGET(`users/${user.email}/accounts/${id}/resources`, token)
          if (data.error) {
            setError(data.error)
          } else {
            setResources(data.resources)
            setVpcs(data.vpcs)
          }
        } else {
          setError("Account not found")
        }

      } catch (err) {
        console.log("ERROR: ", err)
        setError(err)
      }
      setIsLoading(false)
    }
    fetch()

    // Check for favorites status
    const favoriteAcc = favorites.find(a => a.accountId === id)
    if (favoriteAcc != null) {
      setFavoritesEnabled(true)
    } else {
      setFavoritesEnabled(false)
    }

  }, [accounts, favorites, id, user.email, token, dispatch])

  // Set Filtered resources
  useEffect(() => {
    setFilteredResources(resources)
  }, [resources, account])

  // Set Filtered Resources By VPC Id
  const setFilteredResourcesByVpcId = (vid) => {
    const newfilteredResources = filterResourcesByVpcId(resources, vid)
    setFilteredResources(newfilteredResources)
  }
  // Reset Resources (VPCs && Resources)
  const resetResources = () => {
    setFilteredResources(resources)
    setCheckedResources([])
    setSelectedVpc(null)
    setShowClearButton(false)
  }
  // Handle Select VPC
  const handleChangeVpc = (v) => {
    // First VPC
    if (!selectedVpc) {
      setFilteredResourcesByVpcId(v.id)
      setSelectedVpc(v)
      setShowClearButton(true)
    } else {
      // Change VPC without checked Resources
      if (!checkedResources || checkedResources.length === 0) {
        setSelectedVpc(v)
        setFilteredResourcesByVpcId(v.id)
      }
      // Display Alert (changing vpc will removed checked resources)
      else {
        setAlertVpc(v)
        setOpenAlert(true)
      }
    }
  }
  // Handle Check Resources
  const handleCheckResource = (r) => {
    // First Resource and no VPC (Set VPC and Filter Resources)
    if (checkedResources.length === 0 && !selectedVpc) {
      setShowClearButton(true)
      setSelectedVpc(vpcs.find(v => v.id === r.vpcId))
      setFilteredResourcesByVpcId(r.vpcId)
    }
    // Uncheck Resource
    if (checkedResources.find(cr => cr.id === r.id)) {
      setCheckedResources(checkedResources.filter(cr => cr.id !== r.id))
    }
    // Check Resource
    else {
      setCheckedResources([...checkedResources, r])
    }
  }
  // Handle Change VPC with Alert
  const handleAlertChangeVpc = () => {
    setSelectedVpc(alertVpc)
    setFilteredResourcesByVpcId(alertVpc.id)
    setCheckedResources([])
    setAlertVpc(null)
  }
  const handleSearch = event => {
    const value = event.target.value.toLowerCase()
    const resourceTypes = Object.keys(resources)
    let newResources = {}
    resourceTypes.forEach(resource => {
      if (resource.includes(value)) {
        newResources[resource] = resources[resource]
      }
      else {
        const newFilteredResources = resources[resource].filter(r => r.id.includes(value) || r.vpcId.includes(value) || (getNameFromTags(r.tags) && getNameFromTags(r.tags).toLowerCase().includes(value)))
        if (newFilteredResources.length > 0) {
          newResources[resource] = newFilteredResources
        }
      }
    })
    if (selectedVpc) {
      newResources = filterResourcesByVpcId(newResources, selectedVpc.id)
    }
    setFilteredResources(newResources)
  }
  const handleCreateBastion = async (body) => {
    setOpenCreateBastion(false)
    setIsLoading(true)
    try {
      const createRes = await fetchPOST(`users/${user.email}/bastions`, token, body)
      if (createRes.error) {
        setError(createRes.error)
      } else {
        let getBastionByUUID = false
        let count = 25
        do {
          getBastionByUUID = await fetchGET(`users/${user.email}/bastions/uuid/${createRes.uuid}`, token)
          if (!getBastionByUUID) {
            await wait(7000)
          }
          count -= 1
        } while (!getBastionByUUID && count > 0)
        setIsLoading(false)
        if (count === 0 && !getBastionByUUID) {
          setError("ERROR CREATING BASTION: TIMEOUT")
        } else {
          history.push(`/bastions/${getBastionByUUID.id}`)
        }
      }
    } catch (err) {
      console.log("ERROR: ", err)
      setError("ERROR CREATING BASTION")
    }
    setIsLoading(false)
  }

  const handleFavoritesChange = () => {

    if (!favoritesEnabled) {
      // Add To Favorites
      handleAddFavorites()
      
    } else {
      // Remove from Favorites
      handleDeleteFavorites()
    }
  }

  // Handle Add Favorites (post)
  const handleAddFavorites = async () => {
    setIsLoading(true)
    try {
      await fetchPOST(`users/${user.email}/favorites/`, token, {
        userId: user.email,
        accountId: account.accountId,
        accountName: account.accountName,
        departmentName: account.departmentName,
        ownerName: account.ownerName
      })
      setFavoritesEnabled(true)
    } catch (err) {
      console.log("ERROR: ", err)
      setError("Failed to add Favorite")
    }
    setIsLoading(false)
  }

  // Handle Delete Favorites (delete)
  const handleDeleteFavorites = async () => {
    setIsLoading(true)
    try {
      await fetchDELETE(`users/${user.email}/favorites/${id}`, token)
      setFavoritesEnabled(false)
    } catch (err) {
      console.log("ERROR: ", err)
      setError("Failed to delete from Favorites")
    }
    setIsLoading(false)
  }

  if (isLoading || !account) return <Loading />
  if (error) return <Error error={error} />

  return (
    <>
      <Modal open={openAlert} setOpen={setOpenAlert} title="WARNING">
        <Alert setOpen={setOpenAlert} description="Changing VPC will uncheck selected resources" onSubmit={handleAlertChangeVpc} />
      </Modal>
      <Modal open={openCreateBastion} setOpen={setOpenCreateBastion} title="Create Bastion">
        <CreateBastion vpc={selectedVpc} resources={checkedResources} onSubmit={handleCreateBastion} account={account} />
      </Modal>
      <Header title='Account' handleSearch={handleSearch} />

      <h3 className="text-base font-medium text-indigo-600 truncate">{account && account.accountName ? account.accountName : ""}</h3>

      <div class="flex items-center justify-between">

        <p className="text-xs font-medium text-gray-400 truncate">{account && account.accountId ? account.accountId : ""}</p>

        <div class="flex items-center">
          <button type="button" onClick={handleFavoritesChange} className="p-1 text-red-600 hover:text-red-300">
            {favoritesEnabled ? <HeartSolid className="h-7 w-7" aria-hidden="true" /> : <HeartOutline className="h-7 w-7" aria-hidden="true" />}
          </button>
          <p className="text-xs font-medium text-gray-400 truncate">Favorites</p>
        </div>
      </div>

      <div className="mt-5 mb-5">
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-lg font-bold">VPCs</h1>
          <button
            type="button"
            className={`${showClearButton ? '' : 'hidden '}inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300`}
            onClick={resetResources}
          >
            Clear Resources
            <XCircleIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
          </button>
        </div>
        <VpcsRadio vpcs={vpcs} selected={selectedVpc} onChange={handleChangeVpc} />
        <h1 className="text-lg font-bold mt-3 mb-2">Resources</h1>
        <ResourcesCheckbox resourceType="EC2" resources={filteredResources && filteredResources.ec2 ? filteredResources.ec2 : []} checkedResources={checkedResources} handleCheck={handleCheckResource} />
        <ResourcesCheckbox resourceType="Elasticache" resources={filteredResources && filteredResources.elasticache ? filteredResources.elasticache : []} checkedResources={checkedResources} handleCheck={handleCheckResource} />
        <ResourcesCheckbox resourceType="RDS" resources={filteredResources && filteredResources.rds ? filteredResources.rds : []} checkedResources={checkedResources} handleCheck={handleCheckResource} />
        <ResourcesCheckbox resourceType="Redshift" resources={filteredResources && filteredResources.redshift ? filteredResources.redshift : []} checkedResources={checkedResources} handleCheck={handleCheckResource} />
      </div>
      <div className="flex justify-center text-center mb-5">
        <button
          type="button"
          className={selectedVpc && checkedResources && checkedResources.length > 0 ?
            "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full justify-center" :
            "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-400 w-full justify-center cursor-default"
          }
          disabled={selectedVpc && checkedResources && checkedResources.length > 0 ? false : true}
          onClick={() => setOpenCreateBastion(true)}
        >
          Create Bastion
        </button>
      </div>
    </>
  )
}

export default Account
